<template>
  <div>
    <Navbar page="Gestão de projetos" link="/entregaveis" nameLink="Entregáveis" link_2="/entregaveis/especifico" nameLink_2="Relatórios Específicos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">

        <h1 class="text-3xl mb-1">Relatório Específico</h1>
        <a v-if="link" :href="link" target="_blank" class="text-base text-underline text-blue-500">Visualizar relatório: {{ link}}</a>
        <div class="grid grid-cols-12 gap-6 mt-7">
            <div class="col-span-12 md:col-span-5">
                <label for="tipo_relatorio" class="block text-sm font-medium"> 
                    Escolha o tipo do relatório
                </label>
                <select v-model="form.tipo_relatorio" name="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option value="status">Tarefas com determinado status</option>
                    <option value="profissional_responsavel">Tarefas realizadas por determinado profissional</option>
                    <option value="cliente_responsavel">Tarefas realizadas por determinada pessoa na empresa</option>
                    <option value="nivelUrgencia">Tarefas com determinado nível de urgência</option>
                    <option value="fase">Tarefas em determinada fase</option>
                    <option value="evidencias">Tarefas com evidências</option>
                    <option v-if="!$store.state.user.cliente" value="admnistrativo">Relatório admnistrativo projetos empresas</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'status'" class="col-span-12 md:col-span-5">
                <label for="status" class="block text-sm font-medium">
                    Status da atividade
                </label>
                <select v-model="form.status" name="status" id="status" class="mt-2 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option value="Pendente">Pendente</option>
                  <option value="Em Andamento">Em Andamento</option>
                  <option value="Programada">Programada</option>
                  <option value="Concluido">Concluido</option>
                  <option value="Atrasada">Atrasada</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'profissional_responsavel'" class="col-span-12 md:col-span-5">
                <label for="profissional_responsavel" class="block text-sm font-medium">
                    Responsável pela tarefa
                </label>
                <select required v-model="form.profissional_responsavel" name="profissional_responsavel" id="profissional_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'cliente_responsavel'" class="col-span-12 md:col-span-5">
                <label for="cliente_responsavel" class="block text-sm font-medium">
                    Responsável pela tarefa
                </label>
                <select required v-model="form.cliente_responsavel" name="cliente_responsavel" id="cliente_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'nivelUrgencia'" class="col-span-12 md:col-span-5">
                <label for="nivelUrgencia" class="block text-sm font-medium">
                    Nível de Urgência
                </label>
                <select required v-model="form.nivelUrgencia" name="nivelUrgencia" id="nivelUrgencia" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option default value="Baixo">Baixo</option>
                    <option default value="Médio">Médio</option>
                    <option default value="Alto">Alto</option>
                </select>
            </div>
            <div v-if="form.tipo_relatorio === 'fase'" class="col-span-12 md:col-span-5">
                <label for="fase" class="block text-sm font-medium">
                    Fase
                </label>
                <select required v-model="form.fase" name="fase" id="fase" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    <option v-for="fase in fases" :key="fase._id" :value="fase.nome">{{ fase.nome }}</option>
                </select>
            </div>
            
            <div v-if="form.tipo_relatorio !== 'admnistrativo'" class="col-span-12 md:col-span-2">
                <label class="block text-sm font-medium"> 
                    Buscar tarefas
                </label>
                <button @click="buscar()" class="mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
            </div>
             <div v-else class="col-span-12 md:col-span-2">
              <label class="block text-sm font-medium"> 
                    Gerar relatório das tarefas
                </label>
              <button @click="gerarRelatorio()" class="mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Gerar relatório </button>
             </div>
        </div>
        
        <div v-if="list && list.length" class="flex flex-col mt-4">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th v-if="!$store.state.user.cliente" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Fase
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Tarefa
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="item in list" :key="item._id">
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm" id="titularmenor">
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="text-sm text-gray-500">
                              {{item.fase ? item.fase : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-2 py-4">
                          <div class="flex items-center">
                            <div class="text-sm_2 text-gray-500">
                              {{item.nome ? item.nome : ''}}
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <div class="flex items-center">
                            <div v-if="item.status === 'Pendente'" class="text-sm font-semibold text-pendente">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else-if="item.status === 'Em Andamento'" class="text-sm font-semibold text-andamento">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else-if="item.status === 'Programada'" class="text-sm font-semibold text-indigo-600">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else-if="item.status === 'Atrasada'" class="text-sm font-semibold text-danger">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else class="text-sm font-semibold text-concluido">
                              {{ item.status ? item.status: ''}}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination v-model="page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
              </div>
            </div>
        </div>

        <div v-if="selecionados && selecionados.length" class="grid grid-cols-12 gap-6 mt-4">
            <div class="col-span-12 md:col-span-4">
                <label for="titulo" class="block text-sm font-medium">
                    De um título para o relatório
                </label>
                <input v-model="form.titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                <button @click="gerarRelatorio()" class="text-white bg-yellow-400 hover:bg-yellow-500 rounded py-1 px-5 mt-5">
                  Gerar Relatório
                </button>
            </div>
            <div class="col-span-12 md:col-span-6 bg-gray-100 py-4 px-4 rounded md:ml-6">
              <label class="block text-sm font-medium">
                  Tarefas:
              </label>
              <div class="my-2" v-for="(item, idx) in selecionados" :key="idx">
                <span class="text-sm">{{ item.nome }}</span>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            pessoas: [],
            fases: [],
            profissionais: [],
            list: [],
            total: 0,
            page: 1,
            perPage: 20,
            selecionados: [],
            busca: { limit: this.perPage },
            link: "",
            form: {
                profissional_responsavel: null,
                cliente_responsavel: null,
                nivelUrgencia: null,
                fase: null,
                status: ''
            }
        }
    },
    methods: {
        async buscar(){
            this.busca = { limit: this.perPage };

            if(this.form.tipo_relatorio === 'status') {
                if(!this.form.status) return this.$vToastify.error("Escolha o status");
                this.busca.status = this.form.status;
            }

            if(this.form.tipo_relatorio === 'profissional_responsavel') {
                if(!this.form.profissional_responsavel) return this.$vToastify.error("Escolha o responsável");
                this.busca.profissional_responsavel = this.form.profissional_responsavel;
            }

            if(this.form.tipo_relatorio === 'cliente_responsavel') {
                if(!this.form.cliente_responsavel) return this.$vToastify.error("Escolha o responsável");
                this.busca.cliente_responsavel = this.form.cliente_responsavel;
            }

            if(this.form.tipo_relatorio === 'nivelUrgencia') {
                if(!this.form.nivelUrgencia) return this.$vToastify.error("Escolha o nível de urgência");
                this.busca.nivelUrgencia = this.form.nivelUrgencia;
            }

            if(this.form.tipo_relatorio === 'fase') {
                if(!this.form.fase) return this.$vToastify.error("Escolha a fase");
                this.busca.fase = this.form.fase;
            }

            if(this.form.tipo_relatorio === "evidencias") this.busca.evidencias = true;

            const listReq = await this.$http.post(`/v1/tarefas/list`, this.busca);
            if(!listReq.data || !listReq.data.data.length) {
              this.list = [];
              this.total = 0;
              return this.$vToastify.error("Nenhuma atividade encontrada");
            }
            this.list = listReq.data.data;
            this.total = listReq.data.total;
            this.link = "";
        },

        async selecionarTodos () {
          for(let i = 0; i < this.list.length; i++){
            const item = this.list[i];
            if(this.selecionados.map(el => el._id).indexOf(item._id) ==! -1) this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
            else this.selecionados.push(item);
          }
        },

        async setPage(page) {
            this.page = page;
            const skip = (page - 1) * this.perPage;
            const limit = this.perPage;
            
            this.busca.skip = skip;
            this.busca.limit = limit;
            const listReq = await this.$http.post(`/v1/tarefas/list`, this.busca);
            this.list = listReq.data.data;
            this.total = listReq.data.total;
        },

        selecionadosControl(item, e) {
          if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
            this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
          } else {
            this.selecionados.push(item);
          }
        },

        async gerarRelatorio(){
          const body = { titulo: this.form.titulo, tipo_relatorio: this.form.tipo_relatorio, selecionados: this.selecionados.map(el => el._id) }
          if(this.form.status === 'Programada') body.programacao = true;
          
          const req = await this.$http.post(`/v1/entregaveis/especifico/projetos${this.form.tipo_relatorio === 'admnistrativo' ? '-administrativo':''}`, body);
          window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`);
          this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`;
        },
    },
    async beforeMount() {

        const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
        this.pessoas = listPessoas.data.data;

        const profissionaisRef = await this.$http.post(`/v1/profissionais/list`, { all: true }); 
        this.profissionais = profissionaisRef.data.data;

        const fasesReq = await this.$http.post(`/v1/tarefas/fases/list`, { all: true });
        this.fases = fasesReq.data.data;

    }
}
</script>